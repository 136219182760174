<template>
    <div>
        高级资料
    </div>
</template>

<script>
export default {
    name: "EmpAdv"
}
</script>

<style scoped>

</style>